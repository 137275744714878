import { CARD_SETS } from '@markfazzio/pokemon-card-library';

export const SITE_URL = 'https://poketcg.app';
export const OPEN_SEA_URL = 'https://opensea.io/brokemonnft';
export const WHATNOT_USER_URL = 'https://whatnot.com/user/poketcgshop';
export const NETLIFY_API_ENDPOINT = 'https://poketcg.app/.netlify/identity';
export const STOREFRONT_API_VERSION = '2024-01';

// social
export const INSTAGRAM_URL = 'https://www.instagram.com/poketcgshop/';
export const INSTAGRAM_URL_NFT = 'https://www.instagram.com/brokemonnft/';
export const FACEBOOK_APP_ID = '291696392739235';
export const YOUTUBE_CHANNEL_ID = 'UCljbbZwhcLSAOuRaNf26WxA';
export const DISCORD_SERVER_URL = 'https://discord.gg/S7MH6GYVS2';

// site SEO
// meta image
export const META_IMAGE = '/og-images/poke-tcg-og-image.jpg';
export const META_IMAGE_CC = '/og-images/card-creator-og-image.jpg';
export const META_IMAGE_PG = '/og-images/pull-game-og-image.jpg';
export const META_IMAGE_SHOP = '/og-images/shop-og-image.jpg';
export const META_IMAGE_WOTC_SETS = '/og-images/wotc-sets-og-image.jpg';
export const MAIN_SITE_BG = '/bg-home.jpg';

export const IMAGE_CART_NO_PRODUCTS =
  'https://poketcgshop.s3.amazonaws.com/pokemon-images/chansey/chansey-animated_kiaokk.gif';

// meta titles
export const META_TITLE = 'PokéTCGShop';
export const PAGE_CARD_CREATOR_META_TITLE = `Custom Card Creator | ${META_TITLE}`;
export const PAGE_CART_META_TITLE = `Cart | ${META_TITLE}`;
export const PAGE_CATEGORY_META_TITLE = `Category | ${META_TITLE}`;
export const PAGE_CHECKOUT_META_TITLE = `Checkout | ${META_TITLE}`;
export const PAGE_ERROR_META_TITLE = `404: Not Found :( | ${META_TITLE}`;
export const PAGE_MY_ORDERS_META_TITLE = `My Orders | ${META_TITLE}`;
export const PAGE_ORDER_CONFIRMATION_META_TITLE = `Order Confirmed | ${META_TITLE}`;
export const PAGE_PRIVACY_POLICY_META_TITLE = `Privacy Policy | ${META_TITLE}`;
export const PAGE_PROFILE_META_TITLE = `My Profile | ${META_TITLE}`;
export const PAGE_PULL_GAME_META_TITLE = `Pull Game | ${META_TITLE}`;
export const PAGE_SEARCH_META_TITLE = `Search Results | ${META_TITLE}`;
export const PAGE_SET_LISTS_META_TITLE = `Set Lists | ${META_TITLE}`;
export const PAGE_SHOP_META_TITLE = `Shop | ${META_TITLE}`;
export const PAGE_USER_CUSTOMS_META_TITLE = `User Customs | ${META_TITLE}`;
export const PAGE_WOTC_SETS_META_TITLE = `Digital Card Sets - WOTC | ${META_TITLE}`;

// meta descs
export const META_DESCRIPTION =
  'Vintage Pokémon TCG Card Sales and Custom Card Creation! Your one-stop Pokémon hub for all things vintage Pokémon TCG.';
export const PAGE_CARD_CREATOR_META_DESC =
  'Our card creation tool is likely the most advanced one out there - and we are always adding features! Choose between base, gym, neo, and E series templates.';
export const PAGE_MY_ORDERS_META_DESC =
  'View status of current and past orders, track shipments, and customer support options.';
export const PAGE_PROFILE_META_DESC =
  'View my user profile info, manage my created cards and content.';
export const PAGE_SEARCH_META_DESC = 'View search results.';
export const PAGE_PRIVACY_POLICY_META_DESC =
  'This Privacy Policy document contains types of information that is collected and recorded by PokeTCGShop and how we use it.';
export const PAGE_SHOP_META_DESC =
  'Shop vintage singles to complete your collection on PokéTCGShop!';
export const PAGE_USER_CUSTOMS_META_DESC =
  'Custom created digital cards by our very own site users!';
export const PAGE_PULL_GAME_META_DESC =
  'Try to pull the hit cards out of your favorite WOTC sets by opening 36 digital packs with a complete log of your box opening!';
export const PAGE_CART_META_DESC = 'Manage cart items and complete checkout.';
export const PAGE_CHECKOUT_META_DESC =
  'Once you complete your checkout process you will receive an order confirmation email. We will send a follow-up email with tracking information.';
export const PAGE_ERROR_META_DESC =
  'Page not found, please go back to the home page and try your query or action again.';
export const PAGE_WOTC_SETS_META_DESC =
  'View custom created digital card reproductions of original set cards from the TCG.';
export const PAGE_SET_LISTS_META_DESC =
  'View interactive set checklists from all of the TCG sets.';
export const PAGE_ORDER_CONFIRMATION_META_DESC =
  'Your order has been confirmed. You should have an email with your confirmation number, and will receive a tracking number shortly.';

// page descs
export const PAGE_WOTC_SETS_DESC =
  'For all languages besides Japanese, Wizards of the Coast was tasked with creating the cards for the Pokémon TCG. This library contains digital reproductions of the cards from each set, with the holos having a functional animated holo. All of the cards in this library are completely re-created using programming languages, or HTML & CSS to be specific. The only static images are used for the card energy backgrounds, and the actual Pokémon! All of the card elements including energy symbols, fonts, evolution box, and set symbols are all SVG, scalable graphics.';

// error / success messaging
export const ACCOUNT_RECOVERY_MODE =
  'Enter the email address of the account you used to sign up with to send a password recovery email. If the account exists, we will send you a password reset link so you can create a new password.';

export const ACCOUNT_RECOVERY_EMAIL =
  'Please check your email for a password recovery link.';

export const COOKIE_POLICY_MSG =
  'We use cookies to personalize content and ads, to provide social media features and to analyze our traffic to continue making our site better.';

// feature enums
export const MAX_ALLOWED_USER_CARDS_COUNT = 50;

// global loading indicator spacer className
export const LOADING_INDICATOR_CLASS_NAME_CONTENT = 'p-5 text-light';

export const MENU_ITEM_NAMES = {
  BROKEMON_NFT: 'BROkémon NFT',
  DIGITAL_TCG: 'The Digital TCG',
  WOTC_CARDS: 'WOTC Cards',
  SET_LISTS: 'Set Lists',
  WOTC_ANIMATED: 'WOTC Animated Customs',
  USER_CUSTOMS: 'User Customs',
  ABOUT: 'About The Project',
  ROADMAP: 'NFT Roadmap',
  SHOP: 'Shop',
  GAMES: 'Games',
  ADMIN: 'Admin',
  LOGIN_SIGNUP: 'Login/Signup'
};

// Category Slugs (MUST MATCH COMMERCE.JS SLUGS)
export const CATEGORY_SLUG_RAW_CARDS = 'raw-cards';
export const CATEGORY_SLUG_SEALED_ITEMS = 'sealed-items';
export const CATEGORY_SLUG_GRADED_CARDS = 'graded-cards';
export const CATEGORY_SLUG_COMPLETE_SETS = 'complete-sets';
export const CATEGORY_SLUG_ENGLISH_SETS = 'english-sets';
export const CATEGORY_SLUG_JAPANESE_SETS = 'japanese-sets';

// easily map our set ids with TCG API ids
export const TCG_SDK_SET_IDS = {
  BASE: 'base1',
  JUNGLE: 'base2',
  FOSSIL: 'base3',
  BASE_SET_2: 'base4',
  TEAM_ROCKET: 'base5',
  BLACK_STAR_PROMOS: 'basep',
  GYM_HEROES: 'gym1',
  GYM_CHALLENGE: 'gym2',
  NEO_GENESIS: 'neo1',
  NEO_DISCOVERY: 'neo2',
  NEO_REVELATION: 'neo3',
  NEO_DESTINY: 'neo4',
  SOUTHERN_ISLANDS: 'si1',
  LEGENDARY_COLLECTION: 'base6',
  EXPEDITION: 'ecard1',
  AQUAPOLIS: 'ecard2',
  SKYRIDGE: 'ecard3',
  BEST_OF_GAME: 'bp'
};

export const SORT_OPTIONS_VALUES = {
  CARD_NAME: 'name',
  LAST_UPDATED: 'updatedAt'
};

// these generate the available pages in gatsby-node
// add sets here as needed
export const LIVE_PULL_GAME_SETS = [
  CARD_SETS.BASE.value,
  CARD_SETS.JUNGLE.value,
  CARD_SETS.FOSSIL.value,
  CARD_SETS.TEAM_ROCKET.value,
  CARD_SETS.BASE_2.value,
  CARD_SETS.GYM_HEROES.value,
  CARD_SETS.GYM_CHALLENGE.value,
  CARD_SETS.NEO_GENESIS.value,
  CARD_SETS.NEO_DISCOVERY.value,
  CARD_SETS.NEO_REVELATION.value,
  CARD_SETS.NEO_DESTINY.value,
  CARD_SETS.LEGENDARY_COLLECTION.value,
  CARD_SETS.EXPEDITION.value,
  CARD_SETS.AQUAPOLIS.value,
  CARD_SETS.SKYRIDGE.value
];

// remove sets from here once added to LIVE_PULL_GAME_SETS
export const DISABLED_PULL_GAME_SETS = [
  // CARD_SETS.EXPEDITION.value,
  // CARD_SETS.AQUAPOLIS.value,
  // CARD_SETS.SKYRIDGE.value
];

export const SITE_CAROUSEL_ITEMS = [
  {
    imageUrl: MAIN_SITE_BG
  }
  // {
  //   imageUrl:
  //     'https://gumlet.assettype.com/afkgaming%2F2023-09%2F5e7ed573-a8e0-4560-b43d-a2508488d2d9%2FScreenshot__699_.png?compress=true&dpr=1&w=1200',
  // },
  // {
  //   imageUrl:
  //     'https://poketcgshop.s3.amazonaws.com/featured-carousel/featured-carousel-bg-2.jpg',
  // },
  // {
  //   imageUrl:
  //     'https://poketcgshop.s3.amazonaws.com/featured-carousel/featured-carousel-bg-3.jpg',
  // },
  // {
  //   imageUrl:
  //     'https://poketcgshop.s3.amazonaws.com/featured-carousel/featured-carousel-bg-4.jpg',
  // },
  // {
  //   imageUrl:
  //     'https://poketcgshop.s3.amazonaws.com/featured-carousel/featured-carousel-bg-5.jpg',
  // },
];

export const CARD_SET_CARDS_PER_PAGE = 18;
export const ACCOUNT_MIN_PASSWORD_LENGTH = 8;
